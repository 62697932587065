@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;700&display=swap');
*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
}

body{
    font: 400 14px Roboto+Mono, sans-serif;
    background-image: #f0f0f5;
    -webkit-font-smoothing: antialiased;
    background-color: #edededa1;
    
}

input, button, textarea{
    font: 400 18px Roboto, sans-serif;

}

button{
    cursor: pointer;
}
