@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;700&display=swap);
#card {
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
}

body{
    font: 400 14px Roboto+Mono, sans-serif;
    background-image: #f0f0f5;
    -webkit-font-smoothing: antialiased;
    background-color: #edededa1;
    
}

input, button, textarea{
    font: 400 18px Roboto, sans-serif;

}

button{
    cursor: pointer;
}

